<template>
  <section>
      <div class="p-progress-spinner" role="alert" aria-busy="true">
        <svg class="p-progress-spinner-svg" viewBox="25 25 50 50" :style="svgStyle">
            <circle class="p-progress-spinner-circle" cx="50" cy="50" r="20" :fill="fill" :stroke-width="strokeWidth" strokeMiterlimit="10" />
        </svg>
    </div>
    <span><slot></slot></span>
  </section>
</template>

<script>
export default {
    props: {
        strokeWidth: {
            type: String,
            default: '2'
        },
        fill: {
            type: String,
            default: 'none'
        },
        animationDuration: {
            type: String,
            default: '5s'
        }
    },
    computed: {
        svgStyle() {
            return {
                'animation-duration': this.animationDuration
            };
        }
    }
}
</script>

<style scoped>
section {
  width: 100%;
 padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.p-progress-spinner {
    position: relative;
  width: 4rem; /* changed that one */
  margin: 0px 10px 0px 0px; /* changed that one */
    height: 100px;
    display: inline-block;
}

.p-progress-spinner::before {
     content: '';
     display: block;
     padding-top: 100%;
}

.p-progress-spinner-svg {
    animation: p-progress-spinner-rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #d62d20;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes p-progress-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes p-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #002842;
  }
  40% {
    stroke: #0d6097;
  }
  66% {
    stroke: #3F87A9;
  }
  80%,
  90% {
    stroke: #2196F3;
  }
}
</style>